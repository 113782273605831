
		import Async from "../../../node_modules/.pnpm/@preact+async-loader@3.0.2_preact@10.25.4/node_modules/@preact/async-loader/async.js";

		function load(cb) {
			require.ensure([], function (require) {
				var result = require("!!../../../node_modules/.pnpm/babel-loader@8.4.1_@babel+core@7.26.7_webpack@4.47.0/node_modules/babel-loader/lib/index.js??ref--4-0!../../../node_modules/.pnpm/source-map-loader@1.1.3_webpack@4.47.0/node_modules/source-map-loader/dist/cjs.js!./index.js");
				typeof cb === 'function' && cb(result);
			}, "route-orders");
		}

		export default Async(load);
	